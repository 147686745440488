<template>
  <b-row v-if="$route.meta.breadcrumb || $route.meta.pageTitle" class="content-header px-2">
    <!-- Content Left -->
    <b-col class="content-header-left mb-2" cols="12" lg="6" md="6" sm="6">
      <b-row class="breadcrumbs-top">
        <b-col cols="12" class="d-flex align-items-center">
          <h1
            class="content-header-title float-left pr-1 mb-0"
            :class="{ 'content-header-title': !$route.meta.skipGoToHome }"
          >
            {{ $route.meta.pageTitle }}
          </h1>
          <div class="breadcrumb-wrapper" v-if="!$route.meta.skipGoToHome">
            <b-breadcrumb>
              <b-breadcrumb-item :to="dashboardRedirect()">
                <feather-icon icon="HomeIcon" size="16" class="align-text-top" />
              </b-breadcrumb-item>
              <b-breadcrumb-item
                v-for="item in $route.meta.breadcrumb"
                :key="item.text"
                :active="item.active"
                :to="item.to"
              >
                {{ item.text }}
              </b-breadcrumb-item>
            </b-breadcrumb>
          </div>
        </b-col>
        <b-col cols="12" class="d-flex align-items-center" v-if="$route.meta.pageSubtitle">
          <h4 class="float-left pr-1 mt-2">
            {{ $route.meta.pageSubtitle }}
          </h4>
        </b-col>
      </b-row>
    </b-col>

    <!-- Content Right -->
    <b-col class="d-flex justify-content-end align-items-end mb-2" lg="6" md="6" sm="6" cols="12">
      <b-button
        v-for="action in $route.meta.actions"
        :key="action.title"
        :variant="action.variant"
        :to="{ name: action.routeName }"
        @click="emitEvent(action.event)"
        class="ml-1"
      >
        <feather-icon :icon="action.icon" class="mr-50" v-if="action.icon" />
        <span class="align-middle">{{ action.title }}</span>
      </b-button>
    </b-col>
  </b-row>
</template>

<script>
import {
  BBreadcrumb,
  BBreadcrumbItem,
  BRow,
  BCol,
  BDropdown,
  BDropdownItem,
  BButton,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { EventBus } from '@/services/event_bus'
import { getUserData, getHomeRouteForLoggedInUser } from '@/auth/utils'

export default {
  directives: {
    Ripple,
  },
  components: {
    BBreadcrumb,
    BBreadcrumbItem,
    BRow,
    BCol,
    BDropdown,
    BDropdownItem,
    BButton,
  },
  methods: {
    emitEvent(event) {
      if (event) EventBus.$emit(event)
    },
    dashboardRedirect() {
      const userData = getUserData()
      return getHomeRouteForLoggedInUser(userData?.user_type)
    },
  },
}
</script>
