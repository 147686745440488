<template>
  <ul>
    <component
      :is="resolveNavItemComponent(item)"
      v-for="item in menus"
      :key="item.header || item.title"
      :item="item"
    />
  </ul>
</template>

<script>
import { resolveVerticalNavMenuItemComponent as resolveNavItemComponent } from '@core/layouts/utils'
import { provide, ref } from 'vue'
import VerticalNavMenuHeader from '../vertical-nav-menu-header'
import VerticalNavMenuLink from '../vertical-nav-menu-link/VerticalNavMenuLink.vue'
import VerticalNavMenuGroup from '../vertical-nav-menu-group/VerticalNavMenuGroup.vue'

export default {
  components: {
    VerticalNavMenuHeader,
    VerticalNavMenuLink,
    VerticalNavMenuGroup,
  },
  setup() {
    provide('openGroups', ref([]))

    return {
      resolveNavItemComponent,
    }
  },
  computed: {
    menus() {
      const menus = {
        admin: [
          {
            title: 'Dashboard Admin',
            icon: 'GridIcon',
            route: 'admin-dashboard',
            action: 'admin-read',
            resource: 'admin-user'
          },
          {
            header: 'Gestão',
            action: 'admin-read',
            resource: 'admin-user'
          },
          {
            title: 'Concessões',
            icon: 'DollarSignIcon',
            route: 'admin-concessions.index',
            action: 'admin-read',
            resource: 'admin-user'
          },
          {
            title: 'Onboarding',
            icon: 'GridIcon',
            route: 'admin-onboarding-followup.index',
            action: 'admin-read',
            resource: 'admin-user'
          },
          {
            title: 'Usuários',
            icon: 'UsersIcon',
            route: 'admin-users.index',
            action: 'admin-read',
            resource: 'admin-user',
          },
        ],
        empresa: [
          {
            title: 'Dashboard',
            icon: 'GridIcon',
            route: 'dashboard',
            action: 'company-read',
            resource: 'company-user',
          },
          {
            header: 'Cadastros',
            action: 'company-read',
            resource: 'company-user'
          },
          {
            title: 'Concessões',
            icon: 'DollarSignIcon',
            route: 'concessoes.index',
            action: 'company-read',
            resource: 'company-user'
          },
          {
            header: 'Gestão',
            action: 'company-read',
            resource: 'company-user'
          },
          {
            title: 'Minha conta',
            icon: 'UserIcon',
            route: 'minha.conta',
            action: 'company-read',
            resource: 'company-user'
          },
        ],
        parceiro: [
          {
            header: 'Visualização',
            action: 'partner-read',
            resource: 'partner-user'
          },
          {
            title: 'Dashboard',
            icon: 'GridIcon',
            route: 'partner-companies.show',
            action: 'partner-read',
            resource: 'partner-user'
          },
          {
            title: 'Onboarding',
            icon: 'GridIcon',
            route: 'partner-onboarding-followup.index',
            action: 'partner-read',
            resource: 'partner-user'
          },
          {
            title: 'Graficos',
            icon: 'BarChart2Icon',
            route: 'partner-graphs.index',
            action: 'partner-read',
            resource: 'partner-user'
          },
          {
            header: 'Gestão',
            action: 'partner-read',
            resource: 'partner-user'
          },
          {
            title: 'Minha conta',
            icon: 'UserIcon',
            route: 'minha.conta',
            action: 'partner-read',
            resource: 'partner-user'
          },
        ]
      }
      return menus[this.$store.state.auth.userData.user_type]
    },
  },
}
</script>