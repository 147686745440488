<template>
  <b-nav-item-dropdown class="dropdown-notification mr-25" menu-class="dropdown-menu-media" right>
    <template #button-content>
      <feather-icon :badge="counter"
                    badge-classes="bg-danger"
                    class="text-body"
                    icon="BellIcon"
                    size="21"
      />
    </template>

    <!-- Header -->
    <li class="dropdown-menu-header">
      <div class="dropdown-header d-flex">
        <h4 class="notification-title mb-0 mr-auto">
          Notificações
        </h4>
        <b-badge pill variant="light-primary">
          {{ counter }} novas
        </b-badge>
      </div>
    </li>

    <!-- Notifications -->
    <vue-perfect-scrollbar v-once
                           :settings="perfectScrollbarSettings"
                           class="scrollable-container media-list scroll-area"
                           tagname="li"
    >
      <!-- Account Notification -->
      <b-link v-for="notification in customNotifications" :key="notification.index">
        <b-media class="d-flex flex-row justify-content-between align-items-center">
          <div class="d-flex flex-row align-items-center">
            <b-avatar class="notification-avatar"
                      size="32"
                      :src="getCustomAvatar(notification)"
                      :text="getCustomAvatar(notification)"
                      :variant="`light-${notification.mode}`"
            />
            <div class="d-flex flex-column align-items-start">
              <p class="media-heading">
                <span class="font-weight-bolder">
                  {{ notification.title }}
                </span>
              </p>
              <small class="notification-text">{{ notification.text }}</small>
              <small class="notification-timestamp">{{ formatDate(notification.created_at) }}</small>
            </div>
            <div v-if="notification.redirects" class="d-flex flex-row justify-content-end align-items-center">
              <b-button size="sm"
                        variant="outline-primary"
                        class="notification-link"
                        @click="getRedirectRoute(notification)"
              >
                <feather-icon icon="ArrowRightIcon" size="18" />
              </b-button>
            </div>
          </div>
        </b-media>
      </b-link>

      <!-- System Notification Toggler
      <div class="media d-flex align-items-center">
        <h6 class="font-weight-bolder mr-auto mb-0">
          System Notifications
        </h6>
        <b-form-checkbox
          :checked="true"
          switch
        />
      </div>

      -->

      <!-- System Notifications
      <b-link
        v-for="notification in systemNotifications"
        :key="notification.subtitle"
      >
        <b-media>
          <template #aside>
            <b-avatar
              size="32"
              :variant="notification.type"
            >
              <feather-icon :icon="notification.icon" />
            </b-avatar>
          </template>
          <p class="media-heading">
            <span class="font-weight-bolder">
              {{ notification.title }}
            </span>
          </p>
          <small class="notification-text">{{ notification.subtitle }}</small>
        </b-media>
      </b-link>  -->
    </vue-perfect-scrollbar>

    <!-- Cart Footer
    <li class="dropdown-menu-footer"><b-button
      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
      variant="primary"
      block
    >Read all notifications</b-button>
    </li>
    -->
  </b-nav-item-dropdown>
</template>

<script>
import {
  BNavItemDropdown, BBadge, BMedia, BLink, BAvatar, BButton, // BFormCheckbox,
} from 'bootstrap-vue'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import Ripple from 'vue-ripple-directive'
import { formatDate } from '@core/utils/filter'

const canceledExpiredIcon = require('@/assets/images/dashboard/alert-octagon.svg')
const upToDateIcon = require('@/assets/images/dashboard/clock.svg')

export default {
  components: {
    BNavItemDropdown,
    BBadge,
    BMedia,
    BLink,
    BAvatar,
    VuePerfectScrollbar,
    BButton,
    // BFormCheckbox,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      perfectScrollbarSettings: {
        maxScrollbarLength: 60,
        wheelPropagation: false,
      },
    }
  },
  computed: {
    userData() {
      return this.$store.state.auth.userData
    },
    counter() {
      const { notifications } = this.$store.state.auth.userData
      return notifications.map(item => item.read !== true).length
    },
    customNotifications() {
      return this.$store.state.auth.userData.notifications
    },
  },
  methods: {
    getCustomAvatar(notification) {
      let avatar = null
      if (notification.avatar) {
        avatar = notification.avatar
      } else if (notification.notifiable_type === 'Concessao::NotaComercial') {
        if (notification.title === 'Nota Comercial Vencida' || notification.title === 'Nota Comercial Cancelada') avatar = canceledExpiredIcon
        if (notification.title === 'Nota Comercial aguardando pagamento') avatar = upToDateIcon
      }
      return avatar
    },
    formatDate(date) {
      return formatDate(date, true)
    },
    getRedirectRoute(notification) {
      let route = {}
      if (notification.notifiable_type === 'Concessao::NotaComercial') route = { name: 'concessoes.show', params: { id: notification.notifiable_id } }

      if (Object.keys(route).length > 0) {
        if (this.$router.push(route)) { window.location.reload() }
      };
    },
  },
}
</script>

<style>
</style>
