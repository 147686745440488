<template>
  <div class="main-menu menu-fixed menu-accordion menu-shadow" :class="[
    {
      expanded: !isVerticalMenuCollapsed || (isVerticalMenuCollapsed && isMouseHovered),
    },
    skin === 'semi-dark' ? 'menu-dark' : 'menu-light',
  ]" @mouseenter="updateMouseHovered(true)" @mouseleave="updateMouseHovered(false)">
    <!-- main menu header-->
    <div class="navbar-header expanded">
      <slot name="header" :toggleVerticalMenuActive="toggleVerticalMenuActive" :toggleCollapsed="toggleCollapsed"
        :collapseTogglerIcon="collapseTogglerIcon">
        <ul class="nav navbar-nav flex-row">
          <!-- Logo & Text -->
          <li class="nav-item mr-auto">
            <b-link class="navbar-brand" :to="brandLink()">
              <div class="d-flex align-items-center p-0">
                <span class="brand-typography"
                  v-if="!isVerticalMenuCollapsed || (isVerticalMenuCollapsed && isMouseHovered)">
                  <b-img width="170px" :src="appNameImage" alt="logotipo" />
                </span>
                <span class="brand-logo" v-else> <b-img :src="appLogoImage" alt="logomarca" /> </span>
              </div>
              <h2 v-if="appName" class="brand-text">{{ appName }}</h2>
            </b-link>
          </li>

          <!-- Toggler Button -->
          <li class="nav-item nav-toggle d-flex">
            <b-link class="nav-link modern-nav-toggle">
              <feather-icon icon="XIcon" size="20" class="d-block d-xl-none" @click="toggleVerticalMenuActive" />
              <feather-icon :icon="collapseTogglerIconFeather" size="20" class="d-none d-xl-block collapse-toggle-icon"
                @click="toggleCollapsed" />
            </b-link>
          </li>
        </ul>
      </slot>
    </div>
    <!-- / main menu header-->

    <!-- Shadow -->
    <div :class="{ 'd-block': shallShadowBottom }" class="shadow-bottom" />

    <!-- main menu content-->
    <vue-perfect-scrollbar :settings="perfectScrollbarSettings" class="main-menu-content scroll-area" tagname="ul"
      @ps-scroll-y="(evt) => {
          shallShadowBottom = evt.srcElement.scrollTop > 0
        }
        ">
      <vertical-nav-menu-items class="navigation navigation-main" />
      <div class="d-flex align-items-center justify-content-center fixed-bottom pb-2">
        <span v-if="!isVerticalMenuCollapsed || (isVerticalMenuCollapsed && isMouseHovered)">
          <b-img fluid :src="appManufacturerImage" alt="manufacturer logotipo" />
        </span>
        <span class="brand-logo" v-else> <b-img :src="appManufacturerIcon" alt="manufacturer logomarca" /> </span>
      </div>
    </vue-perfect-scrollbar>
    <!-- /main menu content-->
  </div>
</template>

<script>
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import { BLink, BImg } from 'bootstrap-vue'
import { provide, computed, ref } from 'vue'
import useAppConfig from '@core/app-config/useAppConfig'
import { $themeConfig } from '@themeConfig'
import VerticalNavMenuItems from './components/vertical-nav-menu-items/VerticalNavMenuItems.vue'
import useVerticalNavMenu from './useVerticalNavMenu'

export default {
  components: {
    VuePerfectScrollbar,
    VerticalNavMenuItems,
    BLink,
    BImg,
  },
  props: {
    isVerticalMenuActive: {
      type: Boolean,
      required: true,
    },
    toggleVerticalMenuActive: {
      type: Function,
      required: true,
    },
  },
  setup(props) {
    const { isMouseHovered, isVerticalMenuCollapsed, collapseTogglerIcon, toggleCollapsed, updateMouseHovered } =
      useVerticalNavMenu(props)

    const { skin } = useAppConfig()

    // Shadow bottom is UI specific and can be removed by user => It's not in `useVerticalNavMenu`
    const shallShadowBottom = ref(false)

    provide('isMouseHovered', isMouseHovered)

    const perfectScrollbarSettings = {
      maxScrollbarLength: 60,
      wheelPropagation: false,
    }

    const collapseTogglerIconFeather = computed(() =>
      collapseTogglerIcon.value === 'unpinned' ? 'SidebarIcon' : 'XIcon'
    )

    // App Name
    const { appName, appNameImage, appLogoImage, appManufacturerImage, appManufacturerIcon } = $themeConfig.app

    return {
      perfectScrollbarSettings,
      isVerticalMenuCollapsed,
      collapseTogglerIcon,
      toggleCollapsed,
      isMouseHovered,
      updateMouseHovered,
      collapseTogglerIconFeather,

      // Shadow Bottom
      shallShadowBottom,

      // Skin
      skin,

      // App Name
      appName,
      appNameImage,
      appLogoImage,
      appManufacturerImage,
      appManufacturerIcon,
    }
  },
  methods: {
    brandLink() {
      const userType = this.$store.state.auth.userData.user_type
      return { name: userType === 'admin' ? 'admin-dashboard' : 'dashboard' }
    },
  },
}
</script>

<style lang="scss">
@import '~@core/scss/base/core/menu/menu-types/vertical-menu.scss';
</style>
